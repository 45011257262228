import * as React from 'react';
import '../styles/App.less';
import {Helmet} from 'react-helmet';
import {setUTMCookie} from '../utils/CookieUtil';
import {PageProps} from 'gatsby';
import Auth0 from '../components/Login/Auth0';
import {useFlags} from 'launchdarkly-react-client-sdk';

// markup
const IndexPage = (props: PageProps) => {
  const {cloudAwsMarketplaceEnabled} = useFlags();
  const [marketplaceCustomerId, setMarketplaceCustomerId] = React.useState('');
  const [marketplaceProductId, setMarketplaceProductId] = React.useState('');
  const [marketplaceType, setMarketplaceType] = React.useState('');
  setUTMCookie(props);
  React.useLayoutEffect(() => {
    if (cloudAwsMarketplaceEnabled) {
      const params = new URLSearchParams(props?.location?.search);
      setMarketplaceCustomerId(params.get('aws_customer_id') || '');
      setMarketplaceProductId(params.get('product_id') || '');
      setMarketplaceType('aws');
    }
  }, [cloudAwsMarketplaceEnabled]);
  return (
    <>
      <Helmet htmlAttributes={{lang: 'en'}}>
        <meta charSet='utf-8' />
        <title>Cribl.Cloud - Signup</title>
        <link rel='canonical' href='https://cribl.cloud/register' />
      </Helmet>
      <main key={'signup'} className={'main-holder-auth0'}>
        <Auth0
          screen_hint='signup'
          marketplace_customer_id={marketplaceCustomerId}
          marketplace_product_id={marketplaceProductId}
          marketplace_type={marketplaceType}
        />
      </main>
    </>
  );
};

export default IndexPage;
